.scrolling-banner {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background-color: #1f1f22;
    color: #c8be83;
    padding: 10px 0;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  
  .scrolling-banner a {
    display: inline-block;
    padding-left: 100%;
    padding-top: 50px;
    animation: scroll-left 25s linear infinite;
    color: #c8be83;
    text-decoration: none;
    font-weight: bold;
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .register-link {
    text-decoration: underline;
    color: blue; /* or any color you prefer */
    font-weight: bold;
  }